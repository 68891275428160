import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'pdAccounts',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload
    },
    setBankListOptions: (state, action) => {
      state.bankListOptions = action.payload
    },
    setAccountStatus: (state, action) => {
      state.accountStatus = action.payload
    },
    setAccountOptions: (state, action) => {
      state.accountsOptions = action.payload
    },
    setSelectedTransaction: (state, action) => {
      state.selectedTransaction = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },

    setPdAccountsFilters: (state, action) => {
      state.filters = action.payload
    },
    setWithdrawAccount: (state, action) => {
      state.withdrawAccount = action.payload
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setDollarRate: (state, action) => {
      state.dollarRate = action.payload
    },
    setCommercesAmounts: (state, action) => {
      state.commercesAmounts = action.payload
    },
    setSelectedCommerceAccount: (state, action) => {
      state.selectedCommerceAccount = action.payload
    },
    setSelectedWithdrawBankAccount: (state, action) => {
      state.selectedWithdrawBankAccount = action.payload
    },
    setBankAccounts: (state, action) => {
      state.bankAccounts = action.payload
    }
  }
})

export const {
  setLoading,
  setBankListOptions,
  setAccountOptions,
  setAccountStatus,
  setPdAccountsFilters,
  setNextPageExists,
  setSelectedBankTransfer,
  setData,
  setSelectedTransaction,
  setSelectedAccount,
  setWithdrawAccount,
  setDollarRate,
  setCommercesAmounts,
  setBankAccounts,
  setSelectedCommerceAccount,
  setSelectedWithdrawBankAccount
} = slice.actions

export const pdAccounts = slice.reducer