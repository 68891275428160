import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

export const defaultFilter = {
  pagination: {
    limit: 10,
    page: 1
  },
  sorting: {
    field: 'created_at',
    direction: 'DESC'
  },
  input: '',
  filterOptions: {
    input: '',
    category: '',
    type: ''
  }
}

export const defaultExtensionsFilters = {
  pagination: {
    limit: 10,
    page: 1
  },
  commerce_id: '',
  filterOptions: {
    input: ''
  }
}

const defaultValues = {
  pre_username: '',
  username: '',
  custom_name: '',
  name: '',
  short_name: '',
  email: '',
  doc_type: 'J',
  doc_id: '',
  phone: '',
  local_number: '',
  address: '',
  category: '',
  building_id: ''
}

const defaultActions = {
  add: { action: 'add', message: 'Agregar acceso' },
  updateFee: { action: 'updateFee', message: 'Actualizar tarifas' },
  update: { action: 'update', message: 'Actualizar acceso' },
  delete: { action: 'deleted', message: 'Eliminar acceso' }
}

// const defaultActionsCommercesExtensions = {
//   add: { action: 'add', message: 'Agregar extensiones' },
//   update: { action: 'update', message: 'Actualizar extensiones' },
//   delete: { action: 'deleted', message: 'Eliminar extensiones' },
//   exportFile: { action: 'exportFile', message: 'exportar' },
//   loadFile: { action: 'loadFile', message: 'Cargar documento' },
//   updateStatus: { action: 'updateStatus', message: 'actualizar' }
// }

const handleErrors = {
  pre_username: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  username: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  custom_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  short_name: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  email: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    },
    pattern: {
      value: /^\S+@\S+$/i,
      message: 'Correo invalido'
    }
  },

  doc_id: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  phone: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  local_number: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  address: {
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  },
  category: {
    validate: (value) =>
      value.value !== null && value.value !== undefined && value.value !== '',
    required: {
      value: true,
      message: 'Este campo es requerido'
    }
  }
}

const initialState = {
  loading: false,
  defaultValues,
  filters: defaultFilter,
  filtersExtensions: defaultExtensionsFilters,
  actions: defaultActions,
  selectedExtension: null,
  selectedExtensionPD: null,
  selectedCommerceExtension: null,
  selectedPaidExtension: null
}

export default initialState
