// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import initialState from './initialState'

export const slice = createSlice({
  name: 'commerces',
  initialState,
  reducers: {
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setSelectedExtension: (state, action) => {
      state.selectedExtension = action.payload
    },
    setSelectedExtensionPD: (state, action) => {
      state.selectedExtensionPD = action.payload
    },
    setSelectedCommerceExtension: (state, action) => {
      state.selectedCommerceExtension = action.payload
    },
    setSelectedPaidExtension: (state, action) => {
      state.selectedPaidExtension = action.payload
    },

    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export const {
  setNextPageExists,
  setSelectedExtension,
  setSelectedExtensionPD,
  setSelectedCommerceExtension,
  setLoading,
  setSelectedPaidExtension
} = slice.actions

export const commercesExtension = slice.reducer
