import { Fragment, useState, useCallback } from 'react'
import NavbarUser from './NavbarUser'
import NavbarBookmarks from './NavbarBookmarks'
import { getOperatorData, getCommerceData } from '@utils'
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setNavbarOptions, setSidebarOptions } from '@store/superadmin'
import { useNavigate } from 'react-router-dom'
import useResponsive from '@hooks/useResponsive'

const DropdownComponent = ({
  dropdownOpen,
  toggleDropdown,
  navbarOptions,
  handleNavBarItems,
  selectedVertical
}) => (
  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
    <DropdownToggle caret color='primary'>
      {selectedVertical}
    </DropdownToggle>
    <DropdownMenu>
      {navbarOptions
        .filter((navItem) => navItem !== null)
        .map((navItem) => (
          <DropdownItem
            key={navItem.label}
            onClick={() => handleNavBarItems(navItem)}
          >
            {navItem.label}
          </DropdownItem>
        ))}
    </DropdownMenu>
  </Dropdown>
)

const ButtonComponent = ({ navbarOptions, handleNavBarItems }) => (
  <>
    {navbarOptions
      .filter((navItem) => navItem !== null)
      .map((navItem) => (
        <Button
          key={navItem.label}
          color={navItem.isActive ? 'primary' : 'secondary'}
          size='sm'
          style={{ marginRight: '10px' }}
          onClick={() => handleNavBarItems(navItem)}
        >
          {navItem.label}
        </Button>
      ))}
  </>
)

const ThemeNavbar = ({ skin, setSkin, setMenuVisibility }) => {
  const operator = getOperatorData()
  const commerceData = getCommerceData()
  const isSuperadmin = commerceData?.category === 'pagodirecto' || ''
  const { navbarOptions, sidebarOptions } = useSelector(
    (state) => state.superadmin
  )

  const operatorRole = operator?.commerce_role?.name

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isMobile } = useResponsive(992)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedVertical, setSelectedVertical] = useState('Usuario')

  const toggleDropdown = useCallback(
    () => setDropdownOpen((prevState) => !prevState),
    []
  )

  const handleNavBarItems = useCallback(
    (selectedItem) => {
      setSelectedVertical(selectedItem.label)
      const updatedOptions = navbarOptions.map((option) => {
        return option.label === selectedItem.label
          ? { ...option, isActive: true }
          : { ...option, isActive: false }
      })
      dispatch(setNavbarOptions(updatedOptions))
      dispatch(setSidebarOptions(selectedItem.vertical))
      navigate(selectedItem.homeRoute)
    },
    [navbarOptions, dispatch, navigate]
  )

  const abilityOptions = [
    'manager-level-1',
    'sales-level-1',
    'marketing-level-1',
    'atc-level-1',
    'satc-level-1',
    'finance-level-1',
    'sistem-level-1',
    'promoter-level-1'
  ]
  

  const userAbilities = operator?.ability

  const ability = Array.isArray(userAbilities)
    ? userAbilities.find((ua) => abilityOptions.includes(ua.action))?.action
    : undefined

  const filteredNavbarOptions = navbarOptions
    .map((option) => {
      const filteredVertical = option.vertical.filter((item) =>
        item.action.includes(ability)
      )
      return {
        ...option,
        vertical: filteredVertical
      }
    })
    .filter((option) => option.vertical.length > 0)

  return (
    <Fragment>
      <div className='bookmark-wrapper d-flex align-items-center '>
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
        {/* !TODO: fix condition by operator  */}
        {isSuperadmin ? (
          isMobile ? (
            <DropdownComponent
              dropdownOpen={dropdownOpen}
              toggleDropdown={toggleDropdown}
              navbarOptions={filteredNavbarOptions}
              handleNavBarItems={handleNavBarItems}
              selectedVertical={selectedVertical}
            />
          ) : (
            <ButtonComponent
              navbarOptions={filteredNavbarOptions}
              handleNavBarItems={handleNavBarItems}
            />
          )
        ) : (
          <p className='p-0 mt-0 mb-0 d-sm-block d-none'>{`Bienvenido, ${
            operator?.commerce_associated?.name ?? ''
          }`}</p>
        )}
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  )
}

export default ThemeNavbar
