import { apiMethods } from '@services/methods'
import { baseV2URL, baseV1URL } from '@configs/apis'

const endpoints = {
  getAccounts: `${baseV2URL}/superadmin/commerces/list/category`,
  getMovements: `${baseV2URL}/superadmin/commerces/movements`,
  previewWithdraw: `${baseV2URL}/superadmin/withdraw/preview`,
  getDailyUSDRate: `${baseV1URL}/commerces/amounts`,
  getBankOptions: `${baseV1URL}/constants/banklist`,
  getCommercesBankAccounts: `${baseV1URL}/commerces/bank-account`,
  deleteBankAccount: `${baseV1URL}/commerces/bank-account/delete`,
  editBankAccount: `${baseV1URL}/commerces/bank-account/edit`
}

export const updateBankAccountService = async (body) => {
  try {
    const url = `${endpoints.editBankAccount}?id=${body._id}`
    
    const response = await apiMethods.authPatchFetch({
      url,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const deleteBankAccountService = async (id) => {
  try {
    const url = `${endpoints.deleteBankAccount}?id=${id}`
    
    const response = await apiMethods.authPatchFetch({
      url,
      id
    })
    return response
  } catch (error) {
    return error
  }
}

export const addNewAccountService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getCommercesBankAccounts,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getDailyUSDRateService = async () => {
  try {
    const response = await apiMethods.authGetFetch(endpoints.getDailyUSDRate)
    return response
  } catch (error) {
    return error
  }
}
export const getBankOptionsService = async () => {
  try {
    const response = await apiMethods.authGetFetch(endpoints.getBankOptions)
    return response
  } catch (error) {
    return error
  }
}
export const getCommercesBankAccountsService = async () => {
  try {
    const response = await apiMethods.authGetFetch(
      endpoints.getCommercesBankAccounts
    )
    return response
  } catch (error) {
    return error
  }
}

export const getAccountsService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getAccounts,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getMovementsService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.getMovements,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const previewWithdrawService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.previewWithdraw,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
