import { createSlice } from '@reduxjs/toolkit'
import { initialState } from './initialState'

export const slice = createSlice({
  name: 'appInvoice',
  initialState,
  reducers: {
    setLoadingList: (state, action) => {
      state.isLoadingWallet = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setLoadingWithdraw: (state, action) => {
      state.isLoadingWithdraw = action.payload
    },
    setCommercesAssociated: (state, action) => {
      state.commercesAssociated = action.payload
    },
    setMovementsList: (state, action) => {
      state.movementsList = action.payload
    },
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload
    },
    setBankAccounts: (state, action) => {
      state.bankAccounts = action.payload
    },
    setFormFilters: (state, action) => {
      state.formFilters = action.payload
    },
    setNextPageExists: (state, action) => {
      state.nextPageExists = action.payload
    },
    setTotalAmount: (state, action) => {
      state.totalAmount = action.payload
    },
    setWithdrawObject: (state, action) => {
      state.withdrawObject = action.payload
    },
    setChildrenBill: (state, action) => {
      state.childrenBill = action.payload
    },
    setCommerceId: (state, action) => {
      state.commerceId = action.payload
    }
  }
})

export const {
  setIsLoading,
  setLoadingList,
  setLoadingWithdraw,
  setCommercesAssociated,
  setErrorMsg,
  setBankAccounts,
  setFormFilters,
  setNextPageExists,
  setTotalAmount,
  setMovementsList,
  setWithdrawObject,
  setCommerceId,
  setChildrenBill
} = slice.actions

export const wallet = slice.reducer
