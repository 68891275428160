import toast from 'react-hot-toast'

import { setLoading, setBankTransfers } from './slice'

import {
  historyBankTransfersService,
  postTransfersService
} from '../../../services/wallet/bank-transfers'
import { commerceAudit } from '../../../services/wallet'

export const getCommerceAudit = (body) => {
  return async (dispatch) => {
    try {
      const { ok, res, error } = await commerceAudit(body)
      if (!ok) throw error

      toast.success(res.message ?? 'Documento generado exitosamente')
    } catch (err) {
      toast.error(
        err?.message ?? 'Error al buscar los datos de las cuentas bancarias'
      )
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getHistoryBankTransfersService = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const filters = getState().bankTransfers.filters
      const { ok, res, error } = await historyBankTransfersService(filters)

      if (!ok) throw error

      dispatch(setBankTransfers(res?.bank_transactions ?? []))
    } catch (err) {
      toast.error(
        err?.description ?? 'Error al buscar los datos de las cuentas bancarias'
      )
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const postTransfers = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { transferBody } = getState().bankTransfers

      const sendBody = {
        ...transferBody,
        amount: Number(transferBody.amount)
      }

      const { ok, res, error } = await postTransfersService(sendBody)
      if (!ok) throw error

      toast.success('Transferencia realizada con éxito')
    } catch (err) {
      toast.error(err?.description ?? 'Error al realizar la transferencia')
    } finally {
      dispatch(setLoading(false))
    }
  }
}
