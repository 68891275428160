import { apiMethods } from '@services/methods'
import { baseV1URL, baseV2URL, micropd03, micropd04 } from '@configs/apis'

const endpoints = {
  getTransactionsSuperadmin: `${baseV2URL}/superadmin/transactions`,
  getTransactionsAdmin: `${baseV1URL}/commerces/transactions`,
  getAssociateCommercesTransaction: `${baseV1URL}/admin/commerce/associates/transactions`,
  reversePremium: `${baseV2URL}/superadmin/premium/reverse`,
  reverseParkingMeter: `${baseV2URL}/superadmin/reverse/parkingmeter`,
  reverseToll: `${baseV2URL}/superadmin/reverse/toll`,
  reverseParkingOvertime: `${baseV2URL}/superadmin/reverse/overtime`,
  reverseParking: `${baseV2URL}/superadmin/reverse/parking`,
  getTransactionsConstants: `${baseV1URL}/constants`,
  sendMassiveTransfer: `${micropd04}/admin/commerces/massive-balance-update`
}

export const sendMassiveTransferService = async (body) => {
  try {
    const response = await apiMethods.authPostFetchFormData({
      url: endpoints.sendMassiveTransfer,
      formData: body
    })

    return response
  } catch (error) {
    return error
  }
}

export const getAssociateCommercesTransactionService = async (body) => {
  try {
    const url = endpoints.getAssociateCommercesTransaction
    const response = await apiMethods.authPostFetch({
      url,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getTransactionsConstantsService = async () => {
  try {
    const url = endpoints.getTransactionsConstants
    const response = await apiMethods.authGetFetch(url)
    return response
  } catch (error) {
    return error
  }
}

export const getTransactionsServicesAdmin = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.getTransactionsAdmin}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const getTransactionsServicesSuperadmin = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: `${endpoints.getTransactionsSuperadmin}`,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const reversePremiumService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.reversePremium,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const reverseParkingMeterService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.reverseParkingMeter,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const reverseTollService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.reverseToll,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const reverseParkingOvertimeService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.reverseParkingOvertime,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const reverseParkingService = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.reverseParking,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
