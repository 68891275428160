import {
  setAmountsLoading,
  setDashboardLoading,
  setDashboardData,
  setAmounts
} from '@store/commerces'
import { commerceAmounts, commerceDashboard } from '@services/commerces'
import { errors } from '@src/assets/constants/text'

import { formatterTransactions, getDates } from '@utils'
import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import moment from 'moment'
import {
  setLoading,
  setSelectedCommerceExtension,
  setSelectedExtension,
  setSelectedExtensionPD,
  setSelectedPaidExtension,
  slice
} from './'
import {
  addCommercesExtensionService,
  addCommercesExtensionSuperadminService,
  adminCheckCommerceExtension,
  assignAssociatesCommerceService,
  getAssociatesCommerceService,
  getPatientExtensionService,
  getPatientExtensionServiceSuperadmin,
  getPatientInfoService,
  getPatientInfoServiceSuperadmin,
  getPDExtensionServiceSuperadmin,
  superAdminCheckCommerceExtension,
  updateCommerceExtensionService,
  updateCommerceExtensionSuperadminService
} from '../../../services/commerces'
import { doc } from 'prettier'
import { t } from 'i18next'
import {
  payCommerceExtension,
  payCustomerExtension
} from '../../../services/commerces/extension'
import { setCommerceAssociatesOptions, setSelectedCommerceInfo } from '../slice'

const extensionTypes = {
  CUSTOMER: 'customer',
  COMMERCE: 'commerce'
}


export const confirmPayment = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { isSuperadmin } = getState().superadmin
      const adminType = isSuperadmin ? 'superadmin' : 'admin'

      const { selectedPaidExtension, selectedCommerceExtension } =
        getState().commercesExtension
      const extensionType = selectedPaidExtension?.type

      const sendBody = {
        ...(isSuperadmin && {
          issued_commerce: selectedCommerceExtension?.value
        }),
        charge_id: selectedPaidExtension?.bracelet_id,
        charge_flow: 'c2c'
      }

      const { ok, res, error } =
        extensionType === extensionTypes.COMMERCE
          ? await payCommerceExtension(sendBody, adminType)
          : await payCustomerExtension(sendBody, adminType)

      if (!ok) throw error

      toast.success(res.message ?? 'Pago realizado correctamente')
      dispatch(setSelectedPaidExtension(res))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getPatientInfo = (id) => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().superadmin

      const doc_type = id.slice(0, 1)
      const doc_id = id.slice(1)

      dispatch(setLoading(true))

      // check external api
      const { ok, res, error } = isSuperadmin
        ? await getPatientInfoServiceSuperadmin(doc_id)
        : await getPatientInfoService(doc_id)

      if (!ok) throw error

      if (res?.data?.status_code === 404)
        throw { description: 'Paciente no encontrado' }

      const patient = res?.data

      const medicaments = patient.medicamentos_permanentes
        .map((med) => {
          const keys = Object.keys(med)
          const medString = keys.map((key) => `${key}: ${med[key]}`).join(', ')
          return medString
        })
        .join('. ')


      dispatch(
        setSelectedExtension({
          doc_id: patient?.cedula,
          name: patient?.nombres,
          lastName: patient?.apellidos,
          phone: patient?.telefono !== null ? patient?.telefono.slice(1) : '',
          condition: [],
          medicines: medicaments,
          pickup_address: patient?.codigo_brazalete
        })
      )

      const {
        ok: okStepTwo,
        res: resStepTwo,
        error: errorStepTwo
      } = isSuperadmin
        ? await getPatientExtensionServiceSuperadmin(id)
        : await getPatientExtensionService(id)

      if (resStepTwo?.data.length === 0) {
        dispatch(setSelectedExtensionPD(null))
        return
      }

      const pdUser = resStepTwo?.data[0]
      dispatch(setSelectedExtensionPD(pdUser))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getPdExtensionUser = (data) => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().superadmin
      dispatch(setLoading(true))
      dispatch(setSelectedCommerceExtension(data?.commerce))

      const { ok, res, error } = isSuperadmin
        ? await getPDExtensionServiceSuperadmin(data)
        : await getPatientExtensionService(data?.usuario)

      if (!ok) throw error

      if (res?.data.length === 0) {
        toast.error('Usuario no encontrado')
        dispatch(setSelectedExtensionPD(null))
        return
      }

      const pdUser = res?.data[0]
      dispatch(setSelectedExtensionPD(pdUser))
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const addCommercesExtension = (body) => {
  return async (dispatch, getState) => {
    try {
      const { isSuperadmin } = getState().superadmin
      const { selectedExtensionPD, selectedCommerceExtension } =
        getState().commercesExtension

      const sendBody = {
        ...selectedExtensionPD,
        commerce_id: selectedCommerceExtension,
        bracelet_id: body
      }

      const { ok, res, error } = isSuperadmin
        ? await addCommercesExtensionSuperadminService(sendBody)
        : await addCommercesExtensionService(sendBody)

      if (!ok) throw error

      toast.success(res.message ?? 'Extensión cargada correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateCommerceExtension = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { isSuperadmin } = getState().superadmin
      const { selectedExtension, selectedExtensionPD } =
        getState().commercesExtension

      const sendBody = {
        extension_id: selectedExtensionPD?._id,
        fullname: `${selectedExtension?.name} ${selectedExtension?.lastName}`,
        gender: selectedExtension?.gender || 'M',
        bracelet_id: selectedExtension?.bracelet_id,
        pin: `${selectedExtension?.pin}`,
        doc_id: `${selectedExtension?.doc_id}`,
        funds: '',
        is_massive: false,
        mobile_phone: `${selectedExtension?.phone}`,
        pathologies: `${selectedExtension?.condition}`,
        recipes: `${selectedExtension?.medicines}`
      }

      const { ok, res, error } = isSuperadmin
        ? await updateCommerceExtensionSuperadminService(sendBody)
        : await updateCommerceExtensionService(sendBody)

      if (!ok) throw error
      // dispatch(getCommercesExtensions())
      // dispatch(setSelectedCommerceExtension(res.data.extension))
      toast.success(res.message ?? 'Extensión editada correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const checkCommerceExtension = (data) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))

      const { isSuperadmin } = getState().superadmin

      const sendBody = {
        charged_user_id: data?.id
      }

      const { ok, res, error } = isSuperadmin
        ? await superAdminCheckCommerceExtension(sendBody)
        : await adminCheckCommerceExtension(sendBody)

      if (!ok) throw error
      dispatch(setSelectedPaidExtension(res))

      toast.success(res.message ?? 'Extensión encontrada correctamente')
    } catch (err) {
      toast.error(err?.description ?? errors.GENERAL_ERR)
      throw err
    } finally {
      dispatch(setLoading(false))
    }
  }
}
