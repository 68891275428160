import toast from 'react-hot-toast'
import { BasicToastContent } from '@src/components/basic-toast-content'
import { errors } from '@src/assets/constants/text'

import {
  setNextPageExists,
  setLoading,
  setAccountOptions,
  setData,
  setSelectedAccount,
  setWithdrawAccount,
  setBankListOptions,
  setBankAccounts,
  setDollarRate,
  setCommercesAmounts
} from './slice'

import {
  addNewAccountService,
  deleteBankAccountService,
  getAccountsService,
  getBankOptionsService,
  getCommercesBankAccountsService,
  getDailyUSDRateService,
  getMovementsService,
  previewWithdrawService,
  updateBankAccountService
} from '../../../services/wallet/accounts'
import { handleShowAmountBs } from '../../../utility/Utils'

export const addNewAccount = (body) => {
  const sendBody = {
    account_description: body?.account_description?.value,
    account_name: body?.account_name,
    account_number: body?.account_number,
    account_type: 'Nacional',
    bank_name: body?.bank_name?.value?.short_name,
    bank_code: body?.bank_name?.value?.prefix,
    currency: 'BS',
    doc_id: body?.doc_id,
    doc_type: body?.doc_type
  }

  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { ok, res, error } = await addNewAccountService(sendBody)

      if (!ok) throw error

      const bankAccounts = res?.bank_accounts ?? []

      dispatch(setBankAccounts(bankAccounts))
      toast.success('Cuenta agregada exitosamente')
    } catch (error) {
      toast.error(error.description ?? errors.default)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getDailyUSDRate = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { ok, res, error } = await getDailyUSDRateService()

      if (!ok) throw error

      const dollarRate = res?.usd_to_bs_rate ?? 0
      const commercesAmounts = res?.user_amounts ?? []

      dispatch(setDollarRate(dollarRate))
      dispatch(setCommercesAmounts(commercesAmounts))
    } catch (error) {
      toast.error(error.description ?? errors.default)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getBankOptions = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { ok, res, error } = await getBankOptionsService()

      if (!ok) throw error

      const activeBankOptions = res?.bank_list?.filter((bank) => bank.active)
      const bankOptions = activeBankOptions?.map((bank) => ({
        value: bank,
        label: `${bank.prefix} - (${bank.short_name})`
      }))

      dispatch(setBankListOptions(bankOptions ?? []))
    } catch (error) {
      toast.error(error.description ?? errors.default)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getCommercesBankAccounts = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { ok, res, error } = await getCommercesBankAccountsService()

      if (!ok) throw error

      const bankAccounts = res?.bank_accounts ?? []
      dispatch(setBankAccounts(bankAccounts))
    } catch (error) {
      toast.error(error.description ?? errors.default)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const deleteBankAccount = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { selectedCommerceAccount } = getState().pdAccounts

      const id = selectedCommerceAccount._id

      const { ok, res, error } = await deleteBankAccountService(id)
      if (!ok) throw error

      toast.success('Cuenta eliminada exitosamente')
      dispatch(getCommercesBankAccounts())
    } catch (err) {
      toast.error(err?.description ?? 'Error al realizar la transferencia')
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const updateBankAccount = (body) => {
  const sendBody = {
    account_description: body?.account_description?.value,
    account_name: body?.account_name,
    account_number: body?.account_number,
    account_type: 'Nacional',
    bank_name: body?.bank_name?.value?.short_name,
    bank_code: body?.bank_name?.value?.prefix,
    currency: 'BS',
    doc_id: body?.doc_id,
    doc_type: body?.doc_type,
    _id: body._id
  }

  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { ok, res, error } = await updateBankAccountService(sendBody)

      if (!ok) throw error

      dispatch(getCommercesBankAccounts())
      toast.success('Cuenta editada exitosamente')
    } catch (error) {
      toast.error(error.description ?? errors.default)
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getAccounts = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const body = {
        category: 'pagodirecto'
      }

      const { ok, res, error } = await getAccountsService(body)

      if (!ok) throw error

      const commercesOptions = res?.commerces?.map((commerce) => ({
        value: commerce._id,
        label: `${commerce.name}: ${handleShowAmountBs(
          commerce.amounts[0].funds
        )}`,
        commerce
      }))

      dispatch(setAccountOptions(commercesOptions ?? []))
      dispatch(setNextPageExists(true))
    } catch (err) {
      toast.error(
        err?.description ?? 'Error al buscar los datos de las cuentas bancarias'
      )
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const getMovements = (body) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().pdAccounts

      const { ok, res, error } = await getMovementsService(filters)
      if (!ok) throw error

      dispatch(setData(res?.transactions ?? []))
    } catch (err) {
      toast.error(err?.description ?? 'Error al realizar la transferencia')
    } finally {
      dispatch(setLoading(false))
    }
  }
}

export const previewWithdraw = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const { filters } = getState().pdAccounts

      const body = {
        commerce_id: filters.customer_id
      }

      const { ok, res, error } = await previewWithdrawService(body)
      if (!ok) throw error
      console.log(res)
      dispatch(setWithdrawAccount(res))
    } catch (err) {
      toast.error(err?.description ?? 'Error al realizar la transferencia')
    } finally {
      dispatch(setLoading(false))
    }
  }
}
