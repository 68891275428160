import { apiMethods } from '@services/methods'
import { baseV1URL, baseV2URL, commerceExtension } from '@configs/apis'

const urlBase = `${baseV1URL}/commerces`

const endpoints = {
  getPatientExtensionSuperadmin: `${baseV1URL}/superadmin/commerce/search-extension/commerce/644c45a92923275ca70bbbba/id`,
  superAdminCheckCommerceExtensionPay: `${baseV1URL}/superadmin/commerces/payments/charge-user/qr`,
  adminCheckCommerceExtensionPay: `${baseV1URL}/admin/commerces/payments/charge-user/qr`,
  adminCheckCommerceExtension: `${baseV1URL}/admin/commerces/payments/transfers/check-id`
}

export const payCommerceExtension = async (body, adminType) => {
  try {
    const url =
      adminType === 'superadmin'
        ? endpoints.superAdminCheckCommerceExtensionPay
        : endpoints.adminCheckCommerceExtensionPay

    const response = await apiMethods.authPostFetch({
      url,
      body
    })
    return response
  } catch (error) {
    return error
  }
}

export const payCustomerExtension = async (body) => {
  try {
    const response = await apiMethods.authPostFetch({
      url: endpoints.superAdminCheckCommerceExtension,
      body
    })
    return response
  } catch (error) {
    return error
  }
}
